import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_top_news_widget_block = _resolveComponent("top-news-widget-block")!

  return (_ctx.contentList?.items.length)
    ? (_openBlock(), _createBlock(_component_top_news_widget_block, {
        key: 0,
        "big-content": _ctx.bigContent,
        "second-col-contents": _ctx.secondColContents,
        "third-col-contents": _ctx.thirdColContents,
        title: _ctx.title,
        "show-divider": !!_ctx.title,
        "btn-label": _ctx.btnLabel,
        "btn-url": _ctx.btnUrl,
        decorators: _ctx.payload?.decorators,
        "background-url": _ctx.backgroundUrl
      }, null, 8, ["big-content", "second-col-contents", "third-col-contents", "title", "show-divider", "btn-label", "btn-url", "decorators", "background-url"]))
    : _createCommentVNode("", true)
}