import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = { "clip-path": "url(#clip0_960_457)" }
const _hoisted_3 = ["fill"]
const _hoisted_4 = { id: "clip0_960_457" }
const _hoisted_5 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("g", _hoisted_2, [
      _createElementVNode("path", {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "M20 21V18C20 16.344 18.656 15 17 15H7C5.344 15 4 16.344 4 18V21H6V18C6 17.448 6.448 17 7 17H17C17.552 17 18 17.448 18 18V21H20ZM16 7C16 4.792 14.208 3 12 3C9.792 3 8 4.792 8 7V9C8 11.208 9.792 13 12 13C14.208 13 16 11.208 16 9V7ZM14 7V9C14 10.104 13.104 11 12 11C10.896 11 10 10.104 10 9V7C10 5.896 10.896 5 12 5C13.104 5 14 5.896 14 7Z",
        fill: _ctx.fill
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("defs", null, [
      _createElementVNode("clipPath", _hoisted_4, [
        _createElementVNode("rect", {
          width: "24",
          height: "24",
          fill: _ctx.fill
        }, null, 8, _hoisted_5)
      ])
    ])
  ]))
}